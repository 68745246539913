import { faGithub, faTwitter } from "@fortawesome/free-brands-svg-icons"
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { graphql, Link } from "gatsby"
import * as React from "react"
import { useMap, Marker, MapContainer, GeoJSON } from "react-leaflet"
import { TwitterTimelineEmbed } from "react-twitter-embed"
import { DefaultBottom } from "../components/banner/DefaultBottom"
import { Post } from "../components/blog/Post"
import { HTMLContent } from "../components/common/Content"
import Layout from "../components/layout/Layout"
import { getIcon, countryStyles } from "../components/utils/MapUtils"
import { MemberInfo } from "../components/utils/Model"
import teamMap from "../components/team/teamMap.json"
import { ThemeToggler } from "gatsby-plugin-dark-mode"

// props
interface Props {
  data: {
    page: MemberInfo
    posts: {
      nodes: Array<{
        id: string
        fields: {
          path: string
          publishedAt: string
        }
        body: string
      }>
    }
  }
}

// markup
const MemberTemplate = (props: Props) => {
  const member = props.data.page
  const posts = props.data.posts.nodes
  const defaultProps = {
    center: {
      lat: member?.location?.lat,
      lng: member?.location?.lon,
    },
    zoom: 5,
  }

  const enableMapView =
    typeof window !== "undefined" &&
    defaultProps.center.lat &&
    defaultProps.center.lng

  const renderMarkers = (): React.ReactNode =>
    member?.location?.lat &&
    member?.location?.lon && (
      <Marker
        key={member.id}
        icon={getIcon(24, 36, 10, 32)}
        position={{
          lat: Number(member?.location?.lat),
          lng: Number(member?.location?.lon),
        }}
      />
    )

  const Map = (): JSX.Element => {
    const map = useMap()
    return (
      <map>
        {renderMarkers()}
        <GeoJSON data={teamMap.features} style={countryStyles} />
      </map>
    )
  }

  return (
    <Layout
      seo={{
        title: `Team | ${props.data.page.title}`,
        hasNoSpring: true,
      }}
      className="team"
    >
      <div className="container member">
        <div className="py-4 pb-6">
          <Link to={"/team"} className="button is-white is-white-spring">
            <FontAwesomeIcon className="icon" icon={faAngleLeft} />
            Spring Team
          </Link>
        </div>
        <h1 className="is-size-1 has-text-weight-bold pb-6">{member.title}</h1>
      </div>
      {enableMapView && (
        <div id="map">
          <MapContainer
            center={[
              Number(defaultProps.center.lat),
              Number(defaultProps.center.lng),
            ]}
            zoom={defaultProps.zoom}
            scrollWheelZoom={false}
          >
            <Map />
          </MapContainer>
        </div>
      )}
      <div className="container member">
        <div
          className={`columns my-0 member-info ${
            enableMapView ? "member-info-map" : ""
          }`}
        >
          <div className="column team-avatar-2 is-3">
            <img
              style={{ maxWidth: 250 }}
              src={member.gravatar}
              alt={member.title}
            />
          </div>
          <div className="column is-9">
            <h2 className="is-size-4 has-text-weight-bold">
              {member.job}
              {`${member.job && " | "}`}
              {member.country}
            </h2>
            {member?.childContentfulMemberBioTextNode?.bio && (
              <HTMLContent
                content={props.data.page.childContentfulMemberBioTextNode?.bio}
                className={"markdown py-4"}
              ></HTMLContent>
            )}
            {(member.github || member.twitter) && (
              <div className="team-social pt-4">
                {member?.github && (
                  <a
                    href={`https://github.com/${member.github}`}
                    className="mr-4"
                    title="Github"
                  >
                    <FontAwesomeIcon icon={faGithub} />
                  </a>
                )}
                {member.twitter && (
                  <a
                    href={`https://twitter.com/${member.twitter}`}
                    title="Twitter"
                    className=""
                  >
                    <FontAwesomeIcon icon={faTwitter} />
                  </a>
                )}
              </div>
            )}
          </div>
        </div>
        <div className="columns">
          <div className="column is-8">
            <div className="has-text-weight-bold is-uppercase mb-6 has-text-secondary">
              Blog posts by {member.title}
            </div>
            <div className="blog mb-6">
              {posts &&
                posts.map((post: any, index: number) => (
                  <Post
                    hideAuthor={true}
                    key={`article-${index}`}
                    post={post}
                  />
                ))}
              {!(posts?.length > 0) && <div>No post.</div>}
            </div>
          </div>
          <div className="column is-4 pl-6 pb-6 twitter-column">
            <div className="has-text-weight-bold is-uppercase mb-2 has-text-secondary">
              @{member.twitter || "springcentral"}
            </div>
            <div className="mb-4">
              <a
                href={`https://twitter.com/intent/user?screen_name=${
                  member.twitter || "springcentral"
                }`}
                className="button is-small is-spring"
              >
                <FontAwesomeIcon
                  style={{ width: 12 }}
                  className="mr-2"
                  icon={faTwitter}
                />
                Follow
              </a>
            </div>
            <ThemeToggler>
              {({ theme }: any) => (
                <TwitterTimelineEmbed
                  sourceType="profile"
                  screenName={member.twitter || "springcentral"}
                  autoHeight
                  noFooter
                  transparent
                  theme={theme}
                  noHeader
                  noScrollbar
                />
              )}
            </ThemeToggler>
          </div>
        </div>
      </div>
      <DefaultBottom />
    </Layout>
  )
}

export default MemberTemplate

// graphQL queries
export const pageQuery = graphql`
  query MemberTemplate($id: String) {
    page: contentfulMember(id: { eq: $id }) {
      id
      title
      twitter
      slug
      job
      gravatar
      githubId
      github
      country
      childContentfulMemberBioTextNode {
        bio
      }
      inactive
      location {
        lat
        lon
      }
    }

    posts: allContentfulBlogPost(
      sort: { order: DESC, fields: fields___publishedAt }
      filter: { author: { id: { eq: $id } } }
      limit: 10
    ) {
      nodes {
        ...PostFragment
      }
    }
  }
`
