import { Link } from "gatsby"
import React from "react"

interface Props {
  author: {
    inactive?: boolean
    title: string
    slug: string
  }
}

export const Author = ({ author }: Props) => {
  return (
    <>
      {!author || !!author?.inactive || author?.title === "n/a" ? (
        <>{author?.title || "n/a"}</>
      ) : (
        <Link to={`/team/${author?.slug}`}>{author?.title || "n/a"}</Link>
      )}
    </>
  )
}
